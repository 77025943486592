import React from "react"
import { graphql } from "gatsby"

import IndexLayout from '../layouts'
import Carrousel from "../components/Carrousel"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"

const ContactPage = ({data}) => {
  return (
    <IndexLayout>
      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container ">
            <h1 className="title has-text-centered">
              Contact
            </h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="box has-text-centered">
            <div className="block">
              <h1 className="title ">Tel:</h1>
              <h2 className="subtitle">{data.strapiConfiguration.Tel}</h2>
            </div>
            <div className="block">
              <h1 className="title">Email</h1>
              <h2 className="subtitle">{data.strapiConfiguration.Email}</h2>
            </div>
          </div>

        </div>
      </section>

    </IndexLayout>
  )
}

export default ContactPage


export const pageQuery = graphql`
query ContactQuery {
  strapiConfiguration {
    Tel
    Email
  }
}
`